import styled from "styled-components";
import { Button as AntdButton, Steps, Form } from "antd";
import { ENTITIES } from "constant/entity";

const { Step } = Steps;

const FormContainer = styled(Form)`
  @media only screen and (min-width: 768px) {
    padding: 2.5rem;
    border: 2px solid #e0e0e0;
    border-radius: 12px;
    width: 60%;
  }

  .step-component-title {
    height: 3rem;
    display: flex;
    align-items: center;

    @media only screen and (min-width: 768px) {
      font-size: 1.75vw;
      justify-content: flex-start;
      margin-bottom: 2rem;
    }
  }
`;

const WizardContainer = styled.div`
  @media only screen and (min-width: 768px) {
    display: flex;
    width: 100%;
    height: 100vh;
  }

  .step-component-container {
    padding: 0 20px;

    @media only screen and (min-width: 768px) {
      width: 100%;
      height: fit-content;
      padding: 5rem;
      margin-left: 25vw;
      display: flex;
      justify-content: center;
    }
  }
`;

const MobileButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    display: flex;
    margin-top: 1.5rem;
    text-align: right;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    button {
      background-color: #2d378c !important;
      border: none;
    }
  }
`;

const Button = styled(AntdButton)`
  border-radius: 8px;
  width: 100%;
  margin: 5px 0px;

  &.ant-btn-primary {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
    color: #ffffff;

    span {
      color: #ffffff;
    }

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.primaryHover};
      border-color: ${({ theme }) => theme.colors.primaryHover};
    }
  }

  &.ant-btn-default {
    background-color: #bcbcbc;
    border-color: #bcbcbc;

    span {
      color: #000000;
    }

    &:hover,
    &:focus {
      background-color: #ebebeb;
      border-color: #ebebeb;
    }
  }

  &.ant-btn-social-service {
    background-color: #ff6e03;
    border-color: #ff6e03;

    span {
      color: #ffffff;
      font-weight: bold;
    }

    &:hover,
    &:focus {
      background-color: #ff7916;
      border-color: #ff7916;
    }
  }

  @media only screen and (min-width: 768px) {
    border-radius: 3px;
    width: 40%;
    margin: 5px 30px;
  }
`;

const Sidebar = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    background: rgba(234, 234, 234, 0.28);
    height: 100%;
    width: 25vw;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5rem;
    border-right: 2px solid rgba(0, 0, 0, 0.125);

    .logo-sidebar {
      display: flex;
      margin: 0 auto;
      margin-top: 5rem;
      width: 10rem;
    }
  }
`;

const Topbar = styled.div`
  display: flex;
  width: 100%;

  p {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;

    color: ${({ theme }) => {
      const { colors, entity } = theme;

      switch (entity) {
        case ENTITIES.KAUBAN_NATO:
        case ENTITIES.SERBISYONG_TINUD_ANAY:
          return colors.secondary;
        case ENTITIES.AMPING_KANUNAY:
          return colors.primaryDark;
        case ENTITIES.BPSF:
        case ENTITIES.AKAP_BIGAS:
          return "#192b8b";
        default:
          return colors.primary;
      }
    }};
  }

  @media only screen and (min-width: 768px) {
    height: 10rem;
    font-size: 2.5vw;
    justify-content: center;
    align-items: center;
  }
`;

const MobileWizardStepsContainer = styled.div`
  width: 100%;
  height: 10rem;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: #fff;
  box-shadow: 0px -4px 49px 0px rgba(0, 0, 0, 0.125);
  position: relative;
  z-index: 999;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const MobileWizardSteps = styled(Steps)`
  width: 70%;
  margin: 0 auto;

  .ant-steps-item-container {
    display: flex;

    .ant-steps-item-tail,
    .ant-steps-item-content {
      display: none;
    }

    .ant-steps-item-icon {
      background-color: #e8e9f3;
      border: 14px solid #e8e9f3;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-steps-icon {
        color: #2d378c;
        font-size: 12px;
      }
    }
  }

  .ant-steps-item-process {
    .ant-steps-item-icon {
      background-color: ${({ theme }) => theme.colors.primary};
      border-color: ${({ theme }) => theme.colors.primary};

      .ant-steps-icon {
        color: #fff;
      }
    }
  }
`;

const WizardSteps = styled(Steps)`
  width: 60%;
  display: flex;
  margin: 0 auto;

  .ant-steps-item-container {
    display: flex;
    margin: 0 auto;
    width: 100%;

    .ant-steps-item-tail,
    .ant-steps-item-content {
    }

    .ant-steps-item-icon {
      background-color: #e8e9f3;
      border: 14px solid #e8e9f3;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-steps-icon {
        color: #2d378c;
        font-size: 12px;
      }
    }
  }

  .ant-steps-item-process {
    .ant-steps-item-icon {
      background-color: #2d378c;
      border-color: #2d378c;

      .ant-steps-icon {
        color: #fff;
      }
    }
  }
`;

const WizardStep = styled(Step)``;

export {
  WizardContainer,
  Button,
  MobileButtonContainer,
  ButtonContainer,
  FormContainer,
  Sidebar,
  Topbar,
  MobileWizardStepsContainer,
  MobileWizardSteps,
  WizardSteps,
  WizardStep,
};
