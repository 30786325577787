import React, { Suspense, lazy } from "react";

import { ENTITIES, entity } from "constant/entity";

const mapping = {
  [ENTITIES.AMPING_KANUNAY]: lazy(() =>
    import("components/ampingKanunay/headerWithMigs").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.KAUBAN_NATO]: lazy(() =>
    import("components/kaubanNato/headerWithTopBorder").then(module => ({
      default: module.HeaderWithTopBorder,
    })),
  ),
  [ENTITIES.BPSF]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
  [ENTITIES.SERBISYONG_TINUD_ANAY]: lazy(() =>
    import("components/serbisyongTinudanay/headerWithJavi").then(module => ({
      default: module.HeaderWithJavi,
    })),
  ),
  [ENTITIES.AKAP_BIGAS]: lazy(() =>
    import("components/akapBigas/header").then(module => ({
      default: module.Header,
    })),
  ),
};
const Component = mapping[entity];

export const CitizenDetailHeader = ({ className }) => {
  return (
    <Suspense>
      <Component className={className} />
    </Suspense>
  );
};
