import React, { Suspense, lazy } from "react";

import { ENTITIES, entity } from "constant/entity";

const mapping = {
  [ENTITIES.AMPING_KANUNAY]: lazy(() =>
    import("./AmpingKanunay").then(module => ({
      default: module.AmpingKanunay,
    })),
  ),
  [ENTITIES.KAUBAN_NATO]: lazy(() =>
    import("./KaubanNato").then(module => ({
      default: module.KaubanNato,
    })),
  ),
  [ENTITIES.BPSF]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
  [ENTITIES.SERBISYONG_TINUD_ANAY]: lazy(() =>
    import("./SerbisyongTinudAnay").then(module => ({
      default: module.SerbisyongTinudAnay,
    })),
  ),
  [ENTITIES.AKAP_BIGAS]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
};
const Component = mapping[entity];

export const VideoQrScannerHeader = () => {
  return (
    <Suspense>
      <Component />
    </Suspense>
  );
};
