import { entity } from "constant/entity";

export const getConfig = async () => {
  try {
    const config = await import(`../config/${entity}.json`);

    return config.default;
  } catch (error) {
    const defaultConfig = await import("../config/default.json");
    return defaultConfig.default;
  }
};
