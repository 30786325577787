const { styled } = require("styled-components");
import { Select, Form, Input, InputNumber } from "antd";
import Background from "assets/Background.png";

export const StyledElement = styled.div`
  /* background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 165px;

  @media only screen and (min-width: 768px) {
    background: none;
    padding-top: 0px;
    box-shadow: none;
    margin-top: 0px;
  } */
`;

export const MobileContainer = styled.div`
  border-radius: 65px 65px 0 0; /* Top-left and top-right corners only */
  background: #fff;
  height: auto;
  box-shadow: 0px -3px 16px 0px rgba(0, 0, 0, 0.1);
  min-height: calc(100vh - 165px);

  @media only screen and (min-width: 768px) {
    border-radius: initial; /* Reset border-radius */
    background: none;
    box-shadow: none;
    margin-top: 0px;
  }
`;

export const InputSelectField = styled(Select)`
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;
  padding: 10px 0px;

  .ant-select-selector {
    border: none !important;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border: none !important;
  }
`;

export const FormItem = styled(Form.Item)`
  margin-top: -25px;
  width: 100%;

  input {
    height: 4rem;
    padding: 0 15px;
  }

  .ant-form-item-label {
    top: 25px;
    padding-left: 13px;
    font-size: 14px;
    z-index: 1;
  }

  .ant-form-item-label > label {
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    background-color: white;
    padding: 0px 10px;
  }

  @media only screen and (min-width: 766px) {
    display: block;

    .ant-form-item-label {
      top: 17.5px;
      font-size: 14px;
      z-index: 1;
    }

    .ant-form-item-label > label {
      position: relative;
      display: inline-flex;
      align-items: center;
      max-width: 100%;
      height: 32px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
    }
  }
`;

export const InputNumberField = styled(InputNumber)`
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;
`;

export const InputTextFields = styled(Input)`
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;

  .ant-input {
    position: relative;
    padding: 8px 12px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;

    &:hover {
      border-color: #1890ff;
    }

    &:focus {
      border-color: #1890ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    &.ant-input-disabled {
      background-color: #f5f5f5;
      color: rgba(0, 0, 0, 0.65);
      cursor: not-allowed;
      border-color: #d9d9d9;
    }
  }
`;
