import React from "react";
import {
  Background,
  Gradient,
  Layout,
  LeftSideContainer,
  MobileBackground,
  MobileGradient,
  RightSideContainer,
} from "./Layout";
import BG from "assets/bg.png";
import GRADIENT from "assets/corner_gradient.png";
import MOBILE_BG from "assets/mobile_bg.png";
import MOBILE_GRADIENT from "assets/mobile_gradient.png";
import { LogoImg } from "components/logoImg";
import styled from "styled-components";

const StyledLogoImg = styled(LogoImg)`
  align-self: center;
  margin-top: 50px;
`;

const MainLayout = ({ children }) => {
  return (
    <Layout>
      <LeftSideContainer>
        <StyledLogoImg />
        <Background src={BG} />
      </LeftSideContainer>

      <RightSideContainer>
        {children}
        <Gradient src={GRADIENT} />
      </RightSideContainer>

      <MobileGradient src={MOBILE_GRADIENT} />
      <MobileBackground src={MOBILE_BG} />
    </Layout>
  );
};

export default MainLayout;
