import React, { lazy, Suspense } from "react";
import { ENTITIES, entity } from "constant/entity";

const mapping = {
  [ENTITIES.AMPING_KANUNAY]: lazy(() =>
    import("components/ampingKanunay/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.KAUBAN_NATO]: lazy(() =>
    import("components/kaubanNato/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.BPSF]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
  [ENTITIES.SERBISYONG_TINUD_ANAY]: lazy(() =>
    import("components/serbisyongTinudanay/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.AKAP_BIGAS]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
  [ENTITIES.AKAP_BIGAS]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
};
const Component = mapping[entity];

export const TermsAndConditionsHeader = () => {
  return (
    <Suspense>
      <Component />
    </Suspense>
  );
};
