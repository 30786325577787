import { Avatar, Button, Modal, Spin, Table, message } from "antd";
import React, { useContext, useRef, useState } from "react";
import fallbackIdPrevImg from "assets/no_img_found.png";
import styled from "styled-components";
import { generateIDCard, viewIDCard } from "services/public_registration.service";
import moment from "moment";
import EditForm from "./EditForm";
import { getCitizenDetails, editCitizenDetails, getCitizenByCardNumber } from "services/citizen";
import { SearchContext } from "../SearchContext";
import Swal from "sweetalert2";

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
`;

function IDTable({ data, loading, pagination, resendInterval }) {
  const [loadingIdCard, setLoadingIdCard] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCitizen, setShowCitizen] = useState(null);
  const [idSource, setIdSource] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditModal, setIsModalEditOpen] = useState(false);
  const [loadingData, setIsLoadingData] = useState(false);
  /**
   * @type {React.MutableRefObject<import("antd").FormInstance>}
   */
  const formRef = useRef(null);

  const {
    state,
    stateDispatchMain,
    transformCitizenDetailsToParams,
    setSelectedProvince,
    setSelectedMunicipality,
    citizenData,
    setCitizenData,
  } = useContext(SearchContext);

  const getIDCard = async (citizen, isDownload = false) => {
    const { id: citizenId } = citizen;
    await viewIDCard(citizenId)
      .then(data => {
        const tmpUrl = URL.createObjectURL(data);
        setIdSource(tmpUrl);

        if (isDownload) {
          const link = document.createElement("a");
          link.href = tmpUrl;
          link.download = `${citizenId}.png`;
          link.click();
        }

        setLoadingIdCard(false);
      })
      .catch(error => message.error(error));
  };

  const getCitizenDetail = async citizen => {
    const { first_name, last_name, name_extension, birth_date, card_number } = citizen;
    if (card_number) {
      await getCitizenByCardNumber(card_number)
        .then(response => {
          setSelectedProvince(response?.municipality.province.id);
          setSelectedMunicipality(response?.municipality.id);
          const [birthdate_year, birthdate_month, birthdate_day] = response.birth_date.split("-");
          stateDispatchMain({
            key: "citizenDetails",
            payload: {
              ...state.citizenDetails,
              id: response?.id,
              first_name: response?.first_name,
              last_name: response?.last_name,
              name_extension: response?.name_extension,
              birthdate_year: birthdate_year,
              birthdate_month: birthdate_month,
              birthdate_day: birthdate_day,
              gender: response?.gender.id,
              phone_number: response?.phone_number,
              barangay: response?.barangay.id,
              address: response?.address,
              municipality: response?.municipality.id,
              province: response?.municipality.province.id,
              profile: response?.profile,
              fb_profile_link: response?.fb_profile_link,
            },
          });
          setIsLoadingData(false);
        })
        .catch(error => message.error(error));
    } else {
      await getCitizenDetails(first_name, last_name, name_extension, birth_date)
        .then(response => {
          setSelectedProvince(response?.results[0].municipality.province.id);
          setSelectedMunicipality(response?.results[0].municipality.id);
          const [birthdate_year, birthdate_month, birthdate_day] = response.results[0].birth_date.split("-");
          stateDispatchMain({
            key: "citizenDetails",
            payload: {
              ...state.citizenDetails,
              id: response?.results[0].id,
              first_name: response?.results[0].first_name,
              last_name: response?.results[0].last_name,
              name_extension: response?.results[0].name_extension,
              birthdate_year: birthdate_year,
              birthdate_month: birthdate_month,
              birthdate_day: birthdate_day,
              gender: response?.results[0].gender.id,
              phone_number: response?.results[0].phone_number,
              barangay: response?.results[0].barangay.id,
              address: response?.results[0].address,
              municipality: response?.results[0].municipality.id,
              province: response?.results[0].municipality.province.id,
              profile: response?.results[0].profile,
            },
          });
          setIsLoadingData(false);
        })
        .catch(error => message.error(error));
    }
  };

  const editCitizenDetail = async () => {
    try {
      await formRef.current.validateFields();
      setCitizenData([]);
      const params = transformCitizenDetailsToParams();
      await editCitizenDetails(state.citizenDetails.id, params).then(async response => {
        const { status } = response;
        const responseJson = await response.json();
        if (status === 200) {
          Swal.fire({
            title: "Successly Edited",
            allowOutsideClick: true,
            showCancelButton: true,
            cancelButtonText: "Exit",
            showConfirmButton: false,
            width: "80%",
          });
          const objIndex = citizenData.findIndex(obj => obj.id == responseJson.id);
          const newCitizenData = citizenData.splice(objIndex, 1, responseJson);
          setCitizenData(newCitizenData);
        } else {
          Swal.fire({
            title: "Failed to Edit",
            text: `${responseJson[0] || responseJson.non_field_errors[0] || "Something went wrong"}`,
            allowOutsideClick: true,
            showCancelButton: true,
            cancelButtonText: "Exit",
            showConfirmButton: false,
            width: "80%",
            icon: "error",
            iconColor: "red",
          });
        }
      });
    } catch (err) {
      if (err?.errorFields?.length) {
        const title = err.errorFields.reduce((errorMsg, errorField) => {
          errorField.errors.forEach(error => {
            errorMsg = `${errorMsg}<br />${error}`;
          });
          return errorMsg;
        }, "");
        Swal.fire({
          title,
          allowOutsideClick: true,
          showCancelButton: true,
          cancelButtonText: "Exit",
          showConfirmButton: false,
          width: "80%",
          icon: "error",
          iconColor: "red",
        });
      }
    }
  };
  const showIdModal = async citizen => {
    setLoadingIdCard(true);
    await getIDCard(citizen);
    setShowCitizen(citizen);
    setIsModalOpen(true);
  };

  const showEditModal = async citizen => {
    setIsLoadingData(true);
    await getCitizenDetail(citizen);
    setIsEdit(true);
    setIsModalEditOpen(true);
  };

  const downloadCitizenID = async citizen => {
    await getIDCard(citizen, true);
  };

  const resendSms = async citizen => {
    let timer = 60;
    resendInterval.current = setInterval(() => {
      if (timer > 0) {
        timer -= 1;
      }
      const resendBtn = document.querySelector(`#resend-btn-${citizen.id}`);
      const resendBtnText = resendBtn?.querySelector("span");

      if (resendBtnText) {
        resendBtnText.innerText = `Resend SMS (${timer})`;
      }
      resendBtn?.setAttribute("disabled", "true");

      if (timer === 0) {
        clearInterval(resendInterval.current);
        if (resendBtnText) {
          resendBtnText.innerText = `Resend SMS`;
        }
        resendBtn?.removeAttribute("disabled");
      }
    }, 1000);

    await generateIDCard(citizen.id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleCloseEditModal = () => {
    setIsModalEditOpen(false);
  };

  const renderActions = citizen => {
    return (
      <div className="ActionsContainer">
        <Button size="small" type="link" block onClick={() => showIdModal(citizen)}>
          View ID
        </Button>
        <Button size="small" type="link" block onClick={() => showEditModal(citizen)}>
          Edit Citizen Details
        </Button>
        <Button size="small" type="link" block onClick={() => downloadCitizenID(citizen)}>
          Download ID
        </Button>
        <Button size="small" type="link" block onClick={() => resendSms(citizen)} id={`resend-btn-${citizen.id}`}>
          Resend SMS
        </Button>
      </div>
    );
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "card_number",
      key: "card_number",
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "20%",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "first_name",
      width: "20%",
    },
    {
      title: "Profile Photo",
      dataIndex: "profile",
      key: "profile",
      render: profile => <Avatar src={profile} shape="square" size={64} />,
    },
    {
      title: "Province",
      dataIndex: "province",
      key: "province",
      width: "20%",
      render: (_, record) => <p>{record?.municipality?.province?.name}</p>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => renderActions(record),
    },
  ];

  return (
    <Wrapper>
      <Table loading={loading} columns={columns} dataSource={data} pagination={pagination} id="card_number">
        IDTable
      </Table>
      <Modal
        title="ID Preview"
        visible={isModalOpen}
        onCancel={handleCloseModal}
        footer={[
          <Button key="download" onClick={() => downloadCitizenID(showCitizen)}>
            Download ID
          </Button>,
          <Button key="close" onClick={handleCloseModal}>
            Close
          </Button>,
        ]}
        bodyStyle={{ padding: "8px" }}>
        {loadingIdCard ? (
          <Spin style={{ margin: "3rem auto", width: "100%" }} />
        ) : (
          <img src={idSource || fallbackIdPrevImg} style={{ width: "100%" }} />
        )}
      </Modal>
      <Modal
        width={1000}
        title="Edit Details"
        visible={isEditModal}
        onCancel={handleCloseEditModal}
        maskClosable={false}
        footer={[
          <Button key="close" onClick={handleCloseEditModal}>
            Close
          </Button>,
          <Button key="save" type="primary" onClick={() => editCitizenDetail()}>
            Save Edit
          </Button>,
        ]}
        bodyStyle={{ padding: "8px" }}>
        {loadingData ? (
          <Spin style={{ margin: "3rem auto", width: "100%" }} />
        ) : (
          <EditForm getFormRef={form => (formRef.current = form)} />
        )}
      </Modal>
    </Wrapper>
  );
}

export default IDTable;
