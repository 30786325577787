import { createGlobalStyle } from "styled-components";
import { theme } from "./theme";

const GlobalStyle = createGlobalStyle`
    html, * {
        font-family: 'Montserrat', sans-serif;
        color: ${theme.colors.text.base};
    }

    .ml-1 {
        margin-left: 1rem;
    }

    .custom-upload-photo {
        &--container {
            height: 293px !important;
            background: #F5FAFF !important;

            .button {
                margin-top: 1rem;
                background: #006BCB;
                border: 1px solid #E2E2E2;
                border-radius: 4px;
                width: 284px;
                height: 43px;

                span {
                    color: white;
                }

                &.is-disabled {
                    background: #979797;
                    pointer-events: none;
                }
            }
           
            &.ant-upload-disabled {
                background: rgba(216, 216, 216, 0.06) !important;
            }
        }
    }

    .citizen-preview-list {
        .ant-list-item-meta-avatar {
            align-self: center; 
        }
    }

    .dropzone-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 293px;
        background: #F5FAFF;
        border: 1px dashed #006BCB;

        &.is-disabled {
            background: rgba(216, 216, 216, 0.06);
            cursor: no-drop;

            button {
                pointer-events: none;
                background: #979797;
            }

            svg {
                fill: #979797;
            }
        }

        .upload-files {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }

    .dropzone-container.is-disabled {
        border: 1px dashed #979797;
    }

    .form-citizen-select {
        .ant-select-selector {
            margin-left: 6px !important;
            font-size: 14px !important;
            box-shadow: none !important;
            border-width: 0 0 2px 0 !important;
            background: transparent !important;
            border-radius: unset !important;
            border-color: #9b9b9b !important;
            color: #333333 !important;
        }
    }

    .form-citizen-react-select {
        .react__control {
            margin-left: 6px !important;
            font-size: 14px !important;
            box-shadow: none !important;
            border-width: 0 0 2px 0 !important;
            background: transparent !important;
            border-radius: unset !important;
            border-color: #9b9b9b !important;
            color: #333333 !important;
        }

        .react__indicator-separator {
            display: none;
        }
    }

    // .mr-2 {
    //     margin-right: 2rem;
    // }

    // .ml-2 {
    //     margin-left: 2rem;
    // }

    // .mr-1 {
    //     margin-right: 1rem;
    // }

    // .ml-1 {
    //     margin-left: 1rem;
    // }

    .mr-05 {
        margin-right: 0.5rem;
    }

    .ml-05 {
        margin-left: 0.5rem;
    }
    
    .import-service {   
        .ant-table-cell {
            &.date-redeemend {
                min-width: 10rem;
            }
            &.birthdate {
                min-width: 10rem;
            }
            &.agency {
                min-width: 7rem;
            }
            &.status {
                min-width: 8rem;
            }
            &.event_name {
                min-width: 10rem;
            }
            &.first_name {
                min-width: 8rem;
            }
            &.last_name {
                min-width: 8rem;
            }
            &.event_venue {
                min-width: 10rem;
            }
            &.province {
                min-width: 8rem;
            }
            &.social_service {
                min-width: 15rem;
            }
        }
    }

    .add-citizen--phone {
        .ant-input-number  {
            margin: 6px;
            height: 38px;
            width: 100%;
            font-size: 14px;
            box-shadow: none;
            border-width: 0 0 2px 0;
            background: transparent;
            border-radius: unset;
            border-color: #9b9b9b;
            font-weight: 600;
            color: #333333;
        }
    }
`;

export default GlobalStyle;
