import React, { useContext } from "react";
import { NewCitizenContext } from "../NewCitizenContext";
import { DownloadIdStepContainer } from "pages/Register/components/Register.styled";
import IDBack from "assets/id_back.jpg";
import { useConfig } from "hooks/useConfig";
import styled from "styled-components";
import { ENTITIES, entity } from "constant/entity";

const Container = styled(DownloadIdStepContainer)`
  height: 100%;
`;
const IdImage = styled.img`
  box-shadow: 2px 1px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const IDCard = () => {
  const { IDCard, downloadCitizenID } = useContext(NewCitizenContext);
  const { config } = useConfig();

  const handleRegisterAgain = () => {
    window.location.href = "/agreement";
  };

  return (
    <Container>
      <div className="content-container">
        <div className="text-content">
          <h2 className="header-text">Maraming Salamat sa pag-Rehistro sa {config?.pageTitle?.toUpperCase()}!</h2>
          <p className="help-text">
            Narito ang iyong ID. Click the button below to <span className="help-text-download">download.</span>
          </p>
        </div>
        {entity === ENTITIES.BPSF ? <img src={IDBack} className="id" /> : null}
        <IdImage src={IDCard.url} className="id" />
        <button onClick={downloadCitizenID} className="download-id-btn" disabled={!IDCard.url}>
          Download
        </button>
        <button onClick={handleRegisterAgain} className="register-again-btn">
          Magparehistro muli
        </button>
      </div>
    </Container>
  );
};

export default IDCard;
