import WizardForm from "components/form/wizard";
import React, { useContext, useEffect } from "react";
import { LayoutContainer, LayoutLogoContainer, Logo } from "../components/Layout.styled";
import VerificationInformation from "./steps/VerificationInformation";
import VerificationOTP from "./steps/VerificationOTP";
import logo from "../../../assets/bagong_pilipinas_logo.png";
import { CitizenEditContext } from "./CitizenEditContext";
import CitizenEditPersonalInformation from "./steps/CitizenEditPersonalInformation";
import CitizenEditAddress from "./steps/CitizenEditAddress";
import CitizenEditUpload from "./steps/CitizenEditUpload";
import CitizenEditConfirm from "./steps/CitizenEditConfirm";
import CitizenSuccess from "./steps/CitizenSuccess";
import { useLocation } from "react-router-dom";

const CitizenEditComponent = () => {
  const { checkUserExist, verifyOTP, checkAgreement, stateDispatchMain, state } = useContext(CitizenEditContext);
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const { birth_date, ...otherDetails } = location.state.citizenDetails;
      let birth_year, birth_month, birth_day;

      if (birth_date) {
        [birth_year, birth_month, birth_day] = birth_date.split("-");
        // Remove leading zeros from the month and day
        birth_month = parseInt(birth_month, 10).toString();
        birth_day = parseInt(birth_day, 10).toString();
      }

      // Update the context state
      stateDispatchMain({
        key: "citizenDetailsRegister",
        payload: {
          ...state.citizenDetailsRegister,
          birthdate_year: birth_year,
          birthdate_month: birth_month,
          birthdate_day: birth_day,
          ...otherDetails,
        },
      });
      stateDispatchMain({
        key: "citizenDetails",
        payload: {
          ...state.citizenDetails,
          birthdate_year: birth_year,
          birthdate_month: birth_month,
          birthdate_day: birth_day,
          ...otherDetails,
        },
      });
    }
  }, [location, stateDispatchMain]);

  const steps = [
    {
      title: "Verify it's you",
      component: VerificationInformation,
      hideBackBtn: true,
      asyncValidator: checkUserExist,
    },
    {
      title: "OTP Verification",
      component: VerificationOTP,
      hideBackBtn: false,
      hideTitle: true,
      centerTitle: true,
      btnNextOverwriteTitle: "Ipadala",
      asyncValidator: verifyOTP,
    },
    {
      title: "Personal Information",
      component: CitizenEditPersonalInformation,
      hideBackBtn: true,
    },
    {
      title: "Address",
      component: CitizenEditAddress,
    },
    {
      title: "Upload Image",
      component: CitizenEditUpload,
      hideBackBtn: false,
      hideTitle: true,
      centerTitle: true,
      btnNextOverwriteTitle: "Ipadala",
    },
    {
      title: "Confirm",
      component: CitizenEditConfirm,
      hideTitle: true,
      centerTitle: true,
      btnNextOverwriteTitle: "Ipadala",
      asyncValidator: checkAgreement,
    },
    {
      title: "",
      component: CitizenSuccess,
      hideTitle: true,
      centerTitle: true,
      btnNextOverwriteTitle: "Ipadala",
      hideBackBtn: true,
      hideWizardSteps: true,
      hideNextBtn: true,
    },
  ];

  return (
    <LayoutContainer>
      <LayoutLogoContainer>
        <Logo src={logo} alt="Bagong Pilipinas logo" />
      </LayoutLogoContainer>
      <WizardForm steps={steps} />
    </LayoutContainer>
  );
};

export default CitizenEditComponent;
