export const breakpointsValues = {
  sm: 375, // Small devices (landscape phones)
  md: 768, // Medium devices (tablets)
  lg: 1024, // Large devices (desktops)
  xl: 1280, // Extra large devices (large desktops)
  xxl: 1440, // Extra large devices (large desktops)
};

export const theme = {
  colors: {
    primary: "#ac141e",
    primaryDark: "#9a121b",
    text: {
      base: "#111111",
      primary: "rgb(45,55,140)",
      pink: "rgb(45,55,140)",
      gray: "#979797",
      white: "white",
    },
    bg: {
      input: "#F1F1F1",
    },
  },
  borderRadius: "8",
  breakpoints: {
    ...breakpointsValues,
  },
};
