import React from "react";
import styled from "styled-components";
import { Avatar, Col, Divider, Row } from "antd";
import Title from "antd/lib/typography/Title";
import { useSocialServiceContext } from "../Context";
import { PROGRAMS } from "constant/program";
import { formatMoney } from "utils/money";
import { useQrScannerContext } from "pages/QRScan/Context";

const Container = styled.div``;
const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  .ant-typography {
    margin-bottom: 0;
  }
`;
const CardContainer = styled.div`
  border-radius: 11px;
  position: relative;
  width: 100%;
  height: 60px;
  ${({ bgColor }) => (bgColor ? `background-color: ${bgColor};` : "")};
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;
const Label = styled.div`
  font-size: 0.9em;
  line-height: 1;
  ${({ textColor }) => (textColor ? `color: ${textColor};` : "")};
  flex: 0 0 100%;
`;
const Value = styled.div`
  flex: 0 0 100%;
  font-size: 1.5em;
  font-weight: 600;
  ${({ textColor }) => (textColor ? `color: ${textColor};` : "")};
  span {
    ${({ textColor }) => (textColor ? `color: ${textColor};` : "")};
  }
`;

const logo = {
  [PROGRAMS.AKAP]: "/assets/static/akap-rice.svg",
  [PROGRAMS.MAIP]: "/assets/static/maip.svg",
};

const Card = ({ label, value, bgColor = null, textColor = null }) => {
  return (
    <CardContainer style={{ backgroundColor: bgColor || "inherit" }}>
      <Label style={{ color: textColor || "inherit" }}>{label}</Label>
      <div>
        <Value style={{ color: textColor || "inherit" }}>{value}</Value>
      </div>
    </CardContainer>
  );
};

const ProgramInfo = () => {
  const { citizen } = useSocialServiceContext();
  const { user: agencyUser } = useQrScannerContext();

  const renderPrograms = () =>
    citizen?.codes?.map?.(code => {
      // Only show Akap Rice if agency ID is 2 (DSWD)
      if (agencyUser?.agency?.id === 2 && code.program.id !== PROGRAMS.AKAP) {
        return null;
      }
      // Only show MAIP if agency ID is 1 (DOH)
      if (agencyUser?.agency?.id === 1 && code.program.id !== PROGRAMS.MAIP) {
        return null;
      }

      return (
        <Container key={code.id}>
          <Header>
            <Avatar src={logo[code.program.id]} />
            <Title level={4}>{code.program.name}</Title>
          </Header>
          <Divider />
          <Row gutter={15}>
            <Col span={12}>
              <Card label="Status" value={code.status} bgColor="#C8E0FF" textColor="#1D5094" />
            </Col>
            <Col span={12}>
              <Card
                label="Total Subsidy"
                value={formatMoney(code.total_subsidy)}
                bgColor="#E9EBFF"
                textColor="#293392"
              />
            </Col>
          </Row>
          <Row gutter={15} style={{ marginTop: "15px" }}>
            <Col span={12}>
              <Card
                label="Total Expenditure"
                value={formatMoney(code.total_expenditure)}
                bgColor="#FFEDED"
                textColor="#E8383B"
              />
            </Col>
            <Col span={12}>
              <Card
                label="Remaining Balance"
                value={formatMoney(code.remaining_balance)}
                bgColor="#EBFFF8"
                textColor="#1A6E50"
              />
            </Col>
          </Row>
          <Divider />
        </Container>
      );
    });

  return <>{renderPrograms()}</>;
};

export default ProgramInfo;
