import React, { createContext, useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { ConfigProvider as AntdConfigProvider } from "antd";

import { theme as defaultTheme } from "theme";

import { getConfig } from "utils/configLoader";
import { getTheme } from "utils/themeLoader";

export const ConfigContext = createContext({
  config: null,
  theme: defaultTheme,
});

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(null);
  const [theme, setTheme] = useState(defaultTheme);

  useEffect(() => {
    getConfig().then(configData => {
      setConfig(configData);
      setTheme(getTheme(configData.theme));
    });
  }, []);

  useEffect(() => {
    if (config?.pageTitle) {
      document.title = config.pageTitle;
    }
  }, [config?.pageTitle]);

  return (
    <ConfigContext.Provider value={{ config, theme }}>
      <ThemeProvider theme={theme}>
        <AntdConfigProvider
          config={{
            theme: {
              colorPrimary: theme.colors.primary,
              colorPrimaryHover: theme.colors.primaryDark,
              colorSecondary: theme.colors.secondary,
              colorSecondaryHover: theme.colors.secondaryDark,
            },
          }}>
          {children}
        </AntdConfigProvider>
      </ThemeProvider>
    </ConfigContext.Provider>
  );
};
