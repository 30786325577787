import { entity } from "constant/entity";
import { theme } from "theme";

export const getTheme = themeFromConfig => {
  return {
    ...theme,
    entity,
    colors: {
      ...theme.colors,
      ...themeFromConfig.colors,
    },
  };
};
