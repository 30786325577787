import { Button } from "antd";
import { styled } from "styled-components";

const StyledButton = styled(Button)`
  border-radius: 11px;
  background: #293392;
  width: 100%;
  span {
    color: #ffff;
  }
  z-index: 3;
  position: relative;
`;

export default StyledButton;
