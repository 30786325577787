import { message } from "antd";

export const handleApiValidationErrors = async response => {
  const errors = await response.json();
  message.destroy();
  Object.keys(errors).forEach(field => {
    errors[field].forEach(error => {
      message.error(error);
    });
  });
};
