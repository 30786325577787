import { ROOT_URL } from "../config";
const otp = JSON.parse(localStorage.getItem("otp"));
const getCitizenDetails = async (first_name, last_name, suffix, birth_date) => {
  try {
    const response = await fetch(
      ROOT_URL +
        `/citizen/?first_name=${first_name}&last_name=${last_name}&name_extension=${suffix}&birth_date=${birth_date}`,
      {
        headers: {
          message: otp.message_id,
          otp: otp.otp_message,
          "Content-Type": "application/json charset=UTF-8",
        },
        method: "GET",
      },
    );
    if (response.status === 200) {
      return await response.json();
    }
  } catch (Exception) {
    console.error(Exception);
  }
};

const editCitizenDetails = async (id, citizenDetails) => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/${id}/`, {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
      method: "PATCH",
      body: JSON.stringify(citizenDetails),
    });
    return response;
  } catch (Exception) {
    console.error(Exception);
  }
};

export { getCitizenDetails, editCitizenDetails };
