export const ENTITIES = {
  AMPING_KANUNAY: "ampingkanunay",
  KAUBAN_NATO: "kaubannato",
  BPSF: "bpsf",
  ABTIK_MO_SERBISYO: "abtikmoserbisyo",
  SERBISYONG_TINUD_ANAY: "serbisyongtinudanay",
  SERBISYONG_LAMI_KAAYO: "serbisyonglamikaayo",
  AKAP_BIGAS: "akapbigas",
};
export const entity = process.env.REACT_APP_ROOT_ENTITY || ENTITIES.BPSF;

export const ENTITIES_ID = {
  bpsf: 1,
  kaubannato: 2,
  ampingkanunay: 3,
  abtikmoserbisyo: 4,
  serbisyongtinudanay: 5,
  serbisyonglamikaayo: 6,
  akapbigas: 7,
};
