import { Row } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";
import { styled } from "styled-components";
import { useSocialServiceContext } from "../Context";
import { format, parseISO } from "date-fns";
import { formatMoney } from "utils/money";

const HistoryContainer = styled(Row)`
  margin-bottom: 1rem;
  .fullWidth {
    width: 100%;
  }
`;
const ProgramName = styled.p`
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
`;

const AgencyAcronym = styled.span`
  color: #293392;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
`;

const SocialServiceDate = styled.span`
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
`;

const SocialServiecProposedAmount = styled.span`
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const ProgramDetail = styled.div`
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Vertically center the child elements */
  flex-wrap: wrap; /* Allow the items to wrap as needed */
`;
const StyledTitle = styled(Title)`
  margin-top: 1rem;
  @media only screen and (min-width: 700px) {
    color: white !important;
  }
`;

const HistoryItem = ({
  citizen_program_id,
  proposed_amount,
  created_at,
  agency,
  program: { id, name },
  overrides,
  program_type,
}) => {
  const renderProposedAmount = () => {
    switch (program_type) {
      case 4:
      case 1: {
        return formatMoney(proposed_amount);
      }
      case 2: {
        return overrides?.details || "No Details";
      }
      case 3: {
        return "Attendance";
      }
      default: {
        return "No Proposed Amount";
      }
    }
  };

  return (
    <HistoryContainer className="fullWidth" key={citizen_program_id}>
      <ProgramName className="fullWidth">{id !== 110 ? name : overrides?.program}</ProgramName>
      <ProgramDetail>
        <AgencyAcronym>{agency.id !== 33 ? agency.acronym : overrides.agency}</AgencyAcronym>-
        <SocialServiceDate>{format(parseISO(created_at), "MMMM d, yyyy h:mma")}</SocialServiceDate>
      </ProgramDetail>
      <SocialServiecProposedAmount className="fullWidth">{renderProposedAmount()}</SocialServiecProposedAmount>
    </HistoryContainer>
  );
};

const History = () => {
  const { history: histories } = useSocialServiceContext();

  return (
    <>
      <Row>
        <StyledTitle level={3}>Social Service History</StyledTitle>
      </Row>
      {histories?.map((item, index) => (
        <HistoryItem key={index.toString()} {...item} />
      ))}
    </>
  );
};

export default History;
