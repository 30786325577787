import React from "react";
import { ConfirmContainer } from "pages/Edit/components/Form.styled";
import { Button as AntdButton } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Button = styled(AntdButton)`
  border-radius: 8px;
  width: 100%;
  margin: 5px 0px;

  &.ant-btn-primary {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.primary};

    span {
      color: #ffffff;
    }

    &:hover,
    &:focus {
      background-color: #9a121b;
      border-color: #9a121b;
    }
  }

  &.ant-btn-default {
    background-color: #bcbcbc;
    border-color: #bcbcbc;

    span {
      color: #000000;
    }

    &:hover,
    &:focus {
      background-color: #ebebeb;
      border-color: #ebebeb;
    }
  }

  &.ant-btn-social-service {
    background-color: #ff6e03;
    border-color: #ff6e03;

    span {
      color: #ffffff;
      font-weight: bold;
    }

    &:hover,
    &:focus {
      background-color: #ff7916;
      border-color: #ff7916;
    }
  }

  @media only screen and (min-width: 768px) {
    border-radius: 3px;
    width: 40%;
    margin: 5px 30px;
  }
`;

const CitizenEditConfirm = () => {
  const navigate = useNavigate();
  return (
    <ConfirmContainer>
      <h3 className="header-title">Successfully Edited!</h3>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button type="primary" onClick={() => navigate("/")}>
          Home
        </Button>
      </div>
    </ConfirmContainer>
  );
};

export default CitizenEditConfirm;
