import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { FormItem, InputNumberField, InputSelectField, InputTextFields } from "../reusables";
import { useSocialServiceContext } from "pages/SocialService/Context";
import { getProgramServiceProvider } from "services/citizen";
import { PROGRAMS } from "constant/program";
import { formatMoney } from "utils/money";

const MAIP = ({ form }) => {
  const { citizen } = useSocialServiceContext();
  const [serviceProviderOptions, setServiceProviderOptions] = useState([
    {
      label: "Others",
      value: "others",
    },
  ]);
  const [isShowOthers, setIsShowOthers] = useState(false);
  const maipCode = citizen?.codes?.find(code => code.program.id === PROGRAMS.MAIP);
  const [approvedAmount, setApprovedAmount] = useState(0);
  const balance = useMemo(() => (maipCode?.remaining_balance || 0) - approvedAmount, [maipCode, approvedAmount]);

  const getServiceProviders = async () => {
    try {
      const response = await getProgramServiceProvider();

      if (response.results) {
        const sortedOptions = response.results
          .sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0; // names are equal
          })
          .map(item => ({ label: item.name, value: item.id }));
        setServiceProviderOptions(sortedOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ "overrides.balance": balance });
  }, [balance]);

  useEffect(() => {
    getServiceProviders();
  }, []);

  if (!maipCode) {
    return null;
  }

  return (
    <>
      <FormItem
        label="Service Provider (Clinic / Hospitals etc.)"
        wrapperCol={{
          span: 24,
        }}
        name="overrides.service_provider"
        rules={[
          {
            required: true,
            message: "Service Provider is required",
          },
        ]}>
        <InputSelectField
          showSearch
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
          options={serviceProviderOptions}
          placeholder={"Service Provider (Clinic / Hospitals etc.)"}
          required
          onChange={(value, option) => {
            form.setFieldsValue({ "overrides.service_provider_name": option.label });
            setIsShowOthers(value === "others");
          }}
        />
      </FormItem>
      <div style={{ display: "none" }}>
        <FormItem name="overrides.service_provider_name">
          <></>
        </FormItem>
      </div>
      {isShowOthers ? (
        <FormItem
          label="Please specify"
          wrapperCol={{
            span: 24,
          }}
          name="overrides.service_provider_others"
          rules={[
            {
              required: isShowOthers,
              message: "Service Provider is required",
            },
          ]}>
          <InputTextFields />
        </FormItem>
      ) : null}
      <FormItem
        label="MAIP Service Code"
        wrapperCol={{
          span: 24,
        }}
        name="overrides.service_code">
        <InputTextFields />
      </FormItem>
      <FormItem
        label="Requested Amount"
        wrapperCol={{
          span: 24,
        }}
        name="overrides.requested_amount"
        rules={[
          {
            required: true,
            message: "Requested Amount is required",
          },
        ]}>
        <InputNumberField
          prefix="₱"
          style={{ width: "100%" }}
          placeholder={"Input Amount"}
          formatter={value => formatMoney(value)}
        />
      </FormItem>
      <FormItem
        label="Approved Amount"
        wrapperCol={{
          span: 24,
        }}
        name="proposed_amount"
        rules={[
          {
            required: true,
            message: "Approved Amount is required",
          },
        ]}>
        <InputNumberField
          prefix="₱"
          style={{ width: "100%" }}
          placeholder={"Input Amount"}
          formatter={value => formatMoney(value)}
          onChange={value => {
            setApprovedAmount(value);
          }}
        />
      </FormItem>
      <FormItem
        label="Balance after Approved Amount"
        wrapperCol={{
          span: 24,
        }}
        name="overrides.balance"
        rules={[
          {
            required: true,
            message: "Approved Amount is required",
          },
          {
            validator: (_, value) => {
              if (value === undefined || value === "") {
                return Promise.resolve();
              }

              if (parseFloat(value) < 0) {
                return Promise.reject(new Error("Insufficient balance"));
              }

              return Promise.resolve();
            },
          },
        ]}>
        <InputNumberField
          prefix="₱"
          style={{ width: "100%" }}
          placeholder={"Input Amount"}
          formatter={value => formatMoney(value)}
          readOnly
        />
      </FormItem>
    </>
  );
};
export default MAIP;
