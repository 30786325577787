import { css } from "styled-components";
import { breakpointsValues } from "theme";

export function mobile(...args) {
  return css`
    @media (max-width: ${breakpointsValues.md - 1}px) {
      ${css(...args)}
    }
  `;
}

export function tablet(...args) {
  return css`
    @media (min-width: ${breakpointsValues.md}px) and (max-width: ${breakpointsValues.lg}px) {
      ${css(...args)}
    }
  `;
}

export function desktop(...args) {
  return css`
    @media (min-width: ${breakpointsValues.lg + 1}px) {
      ${css(...args)}
    }
  `;
}

export function styledOptions({ propsToIgnore = [] }) {
  return {
    shouldForwardProp: props => !propsToIgnore.includes(props),
  };
}
